import React from 'react';

import Layout from '../components/Layout';
import SectionTitle from '../components/SectionTitle';
import PageTitle from '../components/PageTitle';
// import CollegeProjects from '../components/projects/CollegeProjects';
// import Img from 'gatsby-image';

export default function BlogLandingPage() {
  return (
    <Layout>
      <PageTitle title="Blog" head="bloghead"/>
      <SectionTitle title="Blog Coming Soon"></SectionTitle>
      {/* <CollegeProjects /> */}
    </Layout>
  )
}
