import React from 'react';

export default function PageTitle({ title, head }) {
    return (
      <header className={head}>
        <div className="container d-flex h-100 align-items-center smallhead">
          <div className="mx-auto text-center">
            <h1 className="text-uppercase">{title}</h1>
          </div>
        </div>
      </header>
    );
}
